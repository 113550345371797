import { Row, Col, Card } from 'react-bootstrap';

function CardDescription({ selCard }) {
	return (
		<Row className='mt-5'>
			<Row className='text-center'>
				<h4>Card Details</h4>
			</Row>
			<Row>
				<Col className='centerChild'>
					<Card>
						<Card.Img className='card' variant='top' src='/assets/DefaultCardBG.png' />
						<Card.Body>
							<Card.Title>Search to select a card</Card.Title>
							<Card.Text></Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Row>
						<Col>Name:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Color:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Type:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Sub-Type:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Traits:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Set:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Rarity:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Attack:</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>Defense:</Col>
						<Col></Col>
					</Row>
				</Col>
			</Row>
		</Row>
	);
};

export default CardDescription;