import { Container, Row, Col } from 'react-bootstrap';

import './style.css';

function Footer(){
	return (
		<Container className='footer-container pb-4'>
			<Row>
				<Col>
					<p>
						This website is designed to become a marketplace for tcg players wanting to trade their cards and/or sell them. ^_^
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<span className='footer-content-placeholder'>Website is Under Development</span>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;