import { useState } from 'react';
import { Row, Col, Form, Button, ListGroup } from 'react-bootstrap';

function StoreCard({
		searchTerm,
		handleCardSearch,
		searchResults,
		setSearchResults,
		selectCard
	}) {
		
	const [srchAct, setSrchAct] = useState(false);
	const handleBlur = () => {
		setTimeout(() => {
			setSrchAct(false);
		}, 200);
		
		if(!searchTerm.trim()) {
			setSearchResults([]);
		};
	};
	return (
		<Row className='mt-5'>
			<Row><h4 className='text-center'>Store a Card</h4></Row>
			<Row>
				<Col>Enter User</Col>
				<Col>Value</Col>
			</Row>
			<Row>
				<Col>
					<Form.Control />
				</Col>
				<Col>
					<Form.Control />
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col>Select Card</Col>
				<Col>Select Quantity</Col>
			</Row>
			<Row>
				<Col>
					<Form.Control
						type = 'text'
						placeholder = 'Search...'
						value = {searchTerm}
						onChange = {handleCardSearch}
						onFocus = {() => setSrchAct(true)}
						onBlur = {handleBlur}
					/>
				</Col>
				<Col><Form.Control type='number' /></Col>
			</Row>
			<Row className='text-center mt-1'>
				<Col>
					{srchAct ?
						<ListGroup>
							{searchResults.length > 0 ? (
								searchResults.map((result, index) => (
									<ListGroup.Item
										key = {index}
										eventKey = {result.Name}
										onClick = {selectCard}
									>
										{result.Name}
									</ListGroup.Item>
								))
							) : ''}
						</ListGroup>
					: ''}
				</Col>
				<Col>
					<Button variant='success'>Add</Button>
				</Col>
			</Row>
		</Row>
	);
};

export default StoreCard;