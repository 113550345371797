import { Container, Navbar, Nav } from 'react-bootstrap';

import './style.css';

function Index(){
	return (
		<Navbar expand='lg' className='navbar'>
			<Container style={{height: '64px'}}>
				<Navbar.Brand href='/' className='nav-brand'>
					<img className='brand' src='/assets/logoSimple.png' alt='mzLogo' />
					<span className='brandMana'>Mana</span>
					<span className='brandZone'>Zone</span>
				</Navbar.Brand>
				<Navbar.Toggle />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ms-auto'>
						<Nav.Link disabled className='navLink'>
							<img src='/assets/WeissIcon.png' alt='Weiss Schwarz Logo' />
						</Nav.Link>
						<Nav.Link disabled className='navLink'>
							<img src='/assets/MtgIcon.png' alt='Magic The Gathering Logo' />
						</Nav.Link>
						<Nav.Link href='/' className='navLink'>
							<img src='/assets/AlphaClashIcon.png' alt='Alpha Clash Logo' />
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Index;