//library components
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//components-common
import Header from './Header/Index';
import Footer from './Footer/Index';

//components-views
import Home from './Home/Index';
import Storage from './Storage/Index';
import About from './About/Index';
import CardList from './CardList/Index';

//components-others
import './style.css';

function App() {
  return (
    <div className='App'>
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path='/*' element={<CardList />} />
				<Route path='/storage' element ={<Storage />} />
				<Route path='/about' element={<About />} />
				<Route path='/test' element={<Home />} />
			</Routes>
			<Footer />
		</BrowserRouter>
    </div>
  );
}

export default App;
