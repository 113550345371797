import { Row, Tabs, Tab } from 'react-bootstrap';

function Header({actTCG, selectActTCG, selectFunction}){
	return (
		<Row>
			<Row>
				<h1 className='text-center'>Storage</h1>
			</Row>
			<Row>
				<Tabs activeKey='tcg3' id='activeTCGTab' onSelect={selectActTCG}>
					<Tab eventKey='tcg1' title='Weiss Schwarz' disabled></Tab>
					<Tab eventKey='tcg2' title='Magic The Gathering' disabled></Tab>
					<Tab eventKey='tcg3' title='Alpha Clash'></Tab>
				</Tabs>
			</Row>
			{actTCG ?
				<Row>
					<Tabs id='activeFunction' onSelect={selectFunction}>
						<Tab eventKey='addCard' title='Add Card'></Tab>
						<Tab eventKey='strCard' title='Add Card to Storage'></Tab>
					</Tabs>
				</Row>
			: null}
		</Row>
	);
};

export default Header;