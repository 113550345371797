import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CardItem from './components/CardItem';

import './style.css';

function Index(){	
	const [ac_CardData, setAC_CardData] = useState([]);
	const [ac_Singles, setAC_Singles] = useState([]);
	useEffect(() => {
		const getAC_Singles = async () => {
			try {
				const response = await fetch('https://mana-zone.online/api/index.php?AlphaClashCardList');
				if(response.ok){
					const data = await response.json();
					setAC_CardData(data);
				} else {
					throw new Error('Failed to fetch data');
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			};

			try {
				const response = await fetch('https://mana-zone.online/api/index.php?AlphaClashSingles');
				if(response.ok){
					const data = await response.json();
					setAC_Singles(data);
				} else {
					throw new Error('Failed to fetch data');
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			};
		};
		
		getAC_Singles();
	}, []);
	
	const convertToCardData = (src, name, price, stock, id, user) => {
		return {
			cardImgSrc: '/assets/CardImgs/' + src + '.png',
			cardName: name,
			cardPrice: price,
			cardStock: stock,
			cardId: id,
			cardSeller: user,
		};
	};
	
	return (
		<Container>
			<Row>
				{ac_Singles.map((singles, index) => (
					<Col xs={12} sm={12} md={12} lg={6} xl={4} key={index}>
						<CardItem cardData={convertToCardData(
							ac_CardData[singles.CardId - 1].acId,
							ac_CardData[singles.CardId - 1].Name,
							singles.Value,
							singles.Qty,
							singles.CardId,
							singles.User,
						)} />
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Index;