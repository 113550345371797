import { Row, Col, Card, Form, Button } from 'react-bootstrap';

function AddCardDetails() {
	return (
		<Row className='mt-5'>
			<Row className='text-center'>
				<h4>Card Details</h4>
			</Row>
			<Row>
				<Col className='centerChild'>
					<Card>
						<Card.Img className='card' variant='top' src='/assets/DefaultCardBG.png' />
						<Card.Body>
							<Card.Title>Upload Image</Card.Title>
							<Card.Text></Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cName'>Card Name:</Form.Label></Col>
						<Col><Form.Control type='text' id='cName' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cColor'>Card Color:</Form.Label></Col>
						<Col><Form.Control type='text' id='cColor' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cType'>Card Type:</Form.Label></Col>
						<Col><Form.Control type='text' id='cType' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cSubType'>Card Sub-Type:</Form.Label></Col>
						<Col><Form.Control type='text' id='cSubType' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cTrait'>Traits:</Form.Label></Col>
						<Col><Form.Control type='text' id='cTrait' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cSet'>Set:</Form.Label></Col>
						<Col><Form.Control type='text' id='cSet' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cRarity'>Rarity:</Form.Label></Col>
						<Col><Form.Control type='text' id='cRarity' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cAttack'>Attack:</Form.Label></Col>
						<Col><Form.Control type='number' id='cAttac' /></Col>
					</Row>
					<Row className='mt-1'>
						<Col><Form.Label htmlFor='cDefense'>Defense:</Form.Label></Col>
						<Col><Form.Control type='number' id='cDefense' /></Col>
					</Row>
				</Col>
			</Row>
			<Row className='text-center mt-1'>
				<Col><Button variant='success'>Add Card</Button></Col>
				<Col><Button variant='warning'>Clear</Button></Col>
			</Row>
		</Row>
	);
};

export default AddCardDetails;