import { Row, Col } from 'react-bootstrap';

function CardItem({ cardData }){
	const checkStockInfo = (stock) => {
		if(stock > 0){
			return stock + ' in Stock';
		} else {
			return 'Out of Stock';
		};
	};
	
	return (
		<div className='cardItem'>
			<div className='cardDesc'>
				<Row>
					<Col></Col>
					<Col>
						<Row>&nbsp;</Row>
						<Row><b>{cardData.cardName}</b></Row>
						<Row><span>₱ {cardData.cardPrice}</span></Row>
						<Row><span>{checkStockInfo(cardData.cardStock)}</span></Row>
						<Row><b>by: {cardData.cardSeller}</b></Row>
					</Col>
				</Row>
			</div>
			<img className='previewImg' src={cardData.cardImgSrc} alt='' />
			<div className='popup-image'>
				<img src={cardData.cardImgSrc} className='popup' alt='' />
			</div>
		</div>
	);
};

export default CardItem;