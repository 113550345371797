import { Container, Row, Col } from 'react-bootstrap';

function About() {
	return (
		<Container className='mt-5'>
			<Row>
				<Col>This website's goal is to become the market place for TCG players here in the Philippines</Col>
			</Row>
			<Row>
				<Col>Currently we don't have much features yet but we are developing the following</Col>
			</Row>
			<Row>
				<Col>-Sort/Filter</Col> 
			</Row>
			<Row>
				<Col>-Post a trade for a card (with login)</Col>
			</Row>
			<Row>
				<Col>-Buy and sell single cards</Col>
			</Row>
			<Row>
				<Col>-Buy and sell decks</Col>
			</Row>
			<Row>
				<Col>-Public chart to see the flow of sold cards</Col>
			</Row>
		</Container>
	);
};

export default About;