import { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';

function FilterMobile({ showFilterProp, setShowFilterProp }){
	const [showFilter, setShowFilter] = useState(false);
	useEffect(() => {
		setShowFilter(showFilterProp);
	}, [showFilterProp]);
	
	const closeFilter = () => {
		setShowFilterProp(false);
	};
	
	return (
		<Modal onHide={closeFilter} show={showFilter}>
			<Modal.Header closeButton>Filter</Modal.Header>
			<Modal.Body>
				<Row>
					<Col><img className='mb-filter' src='/assets/sc-black.png' alt='' /></Col>
					<Col><img className='mb-filter' src='/assets/sc-blue.png' alt='' /></Col>
					<Col><img className='mb-filter' src='/assets/sc-green.png' alt='' /></Col>
					<Col><img className='mb-filter' src='/assets/sc-red.png' alt='' /></Col>
					<Col><img className='mb-filter' src='/assets/sc-white.png' alt='' /></Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='primary' onClick={closeFilter}>Ok</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default FilterMobile;