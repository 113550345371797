const apiURL = 'https://mana-zone.online/api/';

export async function getAlphaClashCardList() {
	const reqURL = apiURL + 'index.php?AlphaClashCardList';
	return fetch(reqURL, {
		method: 'GET',
	}).then((response) => {
		if(!response.ok){
			throw new Error('Get card list Failed');
		} else {
			return response.json();
		};
	}).catch((error) => {
		console.error('Error: ', error);
	});
};