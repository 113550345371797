import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import Header from './components/Header';

import StoreCard from './components/StoreCard';
import CardDescription from './components/CardDescription';
import AddCardDetails from './components/AddCardDetails';

import { getAlphaClashCardList } from '../api/apiAlphaClash.js';

import './style.css';

function Index(){
	//Select which card game to search for
	const [actTCG, setActTCG] = useState('');
	const selectActTCG = (target) => {
		setActTCG(target);
	};
	
	const [actFunc, setActFunc] = useState('');
	const selectFunction = (target) => {
		setActFunc(target);
	};

	//Select which card to add
	const [ cardData, setCardData] = useState([]);
	useEffect(() => {
		getAlphaClashCardList().then((response) => {
			setCardData(response);
		});
	}, []);
	
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const handleCardSearch = (event) => {
		const term = event.target.value;
		setSearchTerm(term);
		
		const filteredItems = cardData.filter(item => item.Name.includes(term));
		setSearchResults(filteredItems);
	};
	
	const [selCard, setSelCard] = useState('');
	const selectCard = (event) => {
		setSelCard(event.target.textContent);
		setSearchTerm(event.target.textContent);
	};
	
	return (
		<Container>
			<Header actTCG={actTCG} selectActTCG={selectActTCG} selectFunction={selectFunction} />
			{actFunc === 'strCard' ? (
				<>
					<CardDescription
						selCard = {selCard}
					/>
					<StoreCard
						searchTerm = {searchTerm}
						handleCardSearch = {handleCardSearch}
						searchResults = {searchResults}
						setSearchResults = {setSearchResults}
						selectCard = {selectCard}
					/>
				</>
			) : actFunc === 'addCard' ? (
				<>
					<AddCardDetails />
				</>
			) : null}
		</Container>
	);
};

export default Index;