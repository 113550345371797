import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

function CardItem({ cardData }){
	const [imageSrc, setImageSrc] = useState('/assets/CardImgs/' + cardData.acId + '.png');
	useEffect(() => {
		setImageSrc('/assets/CardImgs/' + cardData.acId + '.png');
	}, [cardData]);
	const handleImageError = () => {
		setImageSrc('/assets/DefaultCardBG.png');
	};
	
	return (
		<div className='cardItem'>
			<div className='cardDesc'>
				<Row>
					<Col></Col>
					<Col xs={7}>
						<Row className='mt-1'><b style={{fontSize: '16px', color: '#f28d0b'}}>{cardData.Name}</b></Row>
						<Row><span style={{fontSize: '12px'}}>Foil: ₱ {cardData.fValue}</span></Row>
						<Row>
							<span hidden style={{fontSize: '12px', fontWeight: 'bold'}}>
								Foil Stock: {
										(parseInt(cardData.inStock0F) + parseInt(cardData.inStock1F) + parseInt(cardData.inStock2F)) > 0
											? <span style={{color: '#4dc621'}}>Available</span>
											: <span style={{color: '#ec2e2e'}}>Not Available</span>
									}
							</span>
						</Row>
						{cardData.FoilType === '1' ? '' : <>
							<Row>
								<span style={{fontSize: '12px'}}>Non-Foil: ₱ {cardData.nValue}</span>
							</Row>
							<Row>
								<span hidden style={{fontSize: '12px', fontWeight: 'bold'}}>
									Non-Foil Stock: {
											(parseInt(cardData.inStock0) + parseInt(cardData.inStock1) + parseInt(cardData.inStock2)) > 0
												? <span style={{color: '#4dc621'}}>Available</span>
												: <span style={{color: '#ec2e2e'}}>Not Available</span>
										}
								</span>
							</Row>
						</>}
					</Col>
				</Row>
			</div>
			<img className='previewImg' src={imageSrc} alt='' />
			<div className='popup-image'>
				<img onError={handleImageError} src={imageSrc} className='popup' alt='' />
			</div>
		</div>
	);
};

export default CardItem;